/* eslint-disable no-inner-declarations */
import { useEffect } from "react";

export function useIntercom() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Only load Intercom on the client-side
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          function l() {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/fkq4lea3";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          }
          if (d.readyState === "complete") {
            l();
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }
  }, []);
}
