import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/index.js";
import { setContext } from "@apollo/client/link/context";
import React, { useMemo } from "react";

export const Provider = ({
  children,
  uri = "https://cdn.apollos.app",
  churchSlug,
}: {
  children: React.ReactNode;
  uri?: string;
  churchSlug: string;
}) => {
  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri,
    });

    const headersLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          "x-church": churchSlug,
        },
      };
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: headersLink.concat(httpLink),
    });
  }, [churchSlug, uri]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
