import { useLoaderData, useRouteLoaderData } from "@remix-run/react";
import { useRef, useEffect } from "react";

export const DelightedSurvey = () => {
  const loaded = useRef(false);
  const { user, church } = useLoaderData();
  const { plan } = useRouteLoaderData("root");

  useEffect(() => {
    if (!user) {
      return;
    }

    if (typeof window !== "undefined" && loaded.current == false) {
      const { churchId, email, fullName, id } = user || {};
      const { slug, orgName } = church || {};

      !(function (e, t, r, n) {
        if (!e[n]) {
          for (
            var a = (e[n] = []),
              i = [
                "survey",
                "reset",
                "config",
                "init",
                "set",
                "get",
                "event",
                "identify",
                "track",
                "page",
                "screen",
                "group",
                "alias",
              ],
              s = 0;
            s < i.length;
            s++
          ) {
            var c = i[s];
            a[c] =
              a[c] ||
              (function (e) {
                return function () {
                  var t = Array.prototype.slice.call(arguments);
                  a.push([e, t]);
                };
              })(c);
          }
          a.SNIPPET_VERSION = "1.0.1";
          var o = t.createElement("script");
          (o.type = "text/javascript"),
            (o.async = !0),
            (o.src =
              "https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/" +
              r +
              "/" +
              n +
              ".js");
          var p = t.getElementsByTagName("script")[0];
          p.parentNode.insertBefore(o, p);
        }
      })(window, document, "dLv9r46JAbZBeo7M", "delighted");

      /* eslint-disable no-undef */
      delighted.survey({
        email,
        name: fullName,
        createdAt: new Date(),
        properties: {
          userId: id,
          churchId,
          churchSlug: slug,
          orgName,
          planId: plan?.id,
          planName: plan?.name,
        },
      });

      loaded.current = true;
    }
  }, [user, church, plan]);

  return null;
};
