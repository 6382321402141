import { useEffect } from "react";

export function useKeyboardFocus() {
  useEffect(() => {
    const handleTabKey = (e) => {
      if (e.key === "Tab") {
        document.body.classList.add("user-is-tabbing");
      }
    };

    const handleMouseDown = () => {
      document.body.classList.remove("user-is-tabbing");
    };

    const handleFocusIn = (e) => {
      if (document.body.classList.contains("user-is-tabbing")) {
        e.target.classList.add("focus-visible");
      }
    };

    const handleFocusOut = (e) => {
      e.target.classList.remove("focus-visible");
    };

    document.body.addEventListener("keydown", handleTabKey);
    document.body.addEventListener("mousedown", handleMouseDown);
    document.body.addEventListener("focusin", handleFocusIn);
    document.body.addEventListener("focusout", handleFocusOut);

    return () => {
      document.body.removeEventListener("keydown", handleTabKey);
      document.body.removeEventListener("mousedown", handleMouseDown);
      document.body.removeEventListener("focusin", handleFocusIn);
      document.body.removeEventListener("focusout", handleFocusOut);
    };
  }, []);
}
